import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { Button, Container, Form, Nav, Navbar } from "react-bootstrap";
import Loginmodel from "../model/Loginmodel";
function Header() {
  const [show, setShow] = useState(false);
  const [disp, setDisp] = useState(false);

  const handleClose = () => setShow(!show);

  return (
    <div>
      <Navbar className={"py-lg-4"} bg="white" expand="lg">
        <Container>
        <Navbar.Brand href="/" className="logosty">
          Veritas
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-navbar-nav" className={"mob_sty_nav"}>          
          <Nav className="ml-lg-auto">
            <div className={"d-flex align-items-center"}>
              {disp ? (
                <div className={"mr-2"}>
                  <input
                    className="input_search_sty new_input_sty"
                    type="search"
                    placeholder="Search.."
                  />
                </div>
              ) : null}
              <div className={"searchIcon"}>
                <FontAwesomeIcon
                  onClick={() => setDisp(!disp)}
                  icon={faSearch}
                  style={{
                    fontSize: "20px",
                    cursor: "pointer",
                  }}
                  className="mob_search_icon"
                />
              </div>
            </div>
          </Nav>
          <Form inline className="form_sty pl-lg-3">
            <div className="search_sty">
              <select className="select_sty">
                <option className="option_sty ">English</option>
                <option className="option_sty ">Hindi</option>
                <option className="option_sty ">French</option>
              </select>
            </div>
            {/* <div>
              <Select styles={customStyles} options={options} />
            </div> */}

            <Button
              onClick={() => {
                setShow(true);
              }}
              className="Login_btn"
            >
              Login
            </Button>
          </Form>
        </Navbar.Collapse>
        </Container>
      </Navbar>
      <Loginmodel show={show} handleClose={handleClose} />
    </div>
  );
}

export default Header;

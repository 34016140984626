import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faBookmark, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState } from "react";
import { Card, Container, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../Header";

function Newscontentsecond() {
  const [show, setShow] = useState(false);
  const [dotshow, setDotshow] = useState(false);

  return (
    <div>
      <Header />
      <Container>
        <div>
          {/* =========================================tast tab==================================== */}
          <Tab.Container
            id="left-tabs-example"
            className="RequireMentSectionTab"
            defaultActiveKey="seven"
          >
            {" "}
            <div>
              <div eventKey="">
                <div class="mt-5">
                  <h1 className="para_hedding">CAA-NRC Protests</h1>
                  <p className="date_line">19 Oct 2020, Monday</p>
                  <div className="row pb-5">
                    <div className="col-md-9">
                      <Card className="card_sty">
                        <Card.Body>
                          <div className="row">
                            <div>
                              <div className="manage_shear_icon">
                                <h1 className="para_hedding_newscontent">
                                  Factual discrepancies
                                </h1>
                                <div
                                  className="margintop_icon"
                                  onClick={() => setShow(!show)}
                                >
                                  <FontAwesomeIcon
                                    icon={faShareAlt}
                                    style={{ color: "#828282" }}
                                  />
                                  <div>
                                    {show ? (
                                      <div className="social_icons">
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faTwitter}
                                          style={{ color: "#828282" }}
                                        />
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faFacebookSquare}
                                          style={{ color: "#828282" }}
                                        />
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faInstagramSquare}
                                          style={{ color: "#828282" }}
                                        />
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faBookmark}
                                          style={{ color: "#828282" }}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="main_para">
                                <div className="d-flex">
                                  <div>
                                    {" "}
                                    <div className="dots_headding"></div>{" "}
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      CAA Protests in Assam are different from
                                      the rest of the country and don’t have
                                      religious discrimination as the primary
                                      motivation as reported by some sources.
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      It was reported by some media sources that
                                      the student protestors had caused violence
                                      and burned buses resulting in the police
                                      entering into Jamia campus to detain
                                      perpetrators. [2]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      A few media sources reported that the
                                      students had claimed not being involved in
                                      the violence which was being used to
                                      malign the protests and incite violence.
                                      [3]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      It was later revealed in the
                                      investigations that the 10 people arrested
                                      were not Jamia students and Jamia student
                                      protestors had not been involved in the
                                      violence earlier. [4]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      Some reports of violence in West Bengal
                                      with trains being attacked by protestors
                                      [5]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      whereas other reports on the issue claimed
                                      to reveal that the people involved in the
                                      attack were affiliated with the BJP. [6]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      There were claims by some sources that the
                                      shooting at Shaheen Bagh was motivated by
                                      the Union Minister and fueled by BJP
                                      supporters. [7]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      Other sources have alleged that the
                                      shooter was affiliated with the Aam Aadmi
                                      Party [8].
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-3">
                      <Card className="card_sty">
                        <Card.Body>
                          <div className="right_card_sty_second">
                            <div>
                              <p>
                                [1]
                                <a
                                  href="https://economictimes.indiatimes.com/news/politics-and-nation/caa-protests-in-assam-why-it-is-different-from-the-rest-of-the-country/articleshow/72844673.cms?from=mdr"
                                  className="link_sty"
                                >
                                  <u> The Economic Times</u>
                                </a>
                              </p>
                              <p>
                                [2]
                                <a
                                  href="https://www.republicworld.com/india-news/general-news/protestors-set-bus-on-fire-delhi-police-pro-says-on-jamia-violence.html"
                                  className="link_sty"
                                >
                                  <u> REPUBLIC World</u>
                                </a>
                              </p>
                              <p>
                                [3]
                                <a
                                  href="https://scroll.in/article/946933/delhi-police-were-uncontrollable-as-they-unleashed-violence-at-jamia-millia-islamia-students-say"
                                  className="link_sty"
                                >
                                  <u> Scroll</u>
                                </a>
                              </p>
                              <p>
                                [4]
                                <a
                                  href="https://www.hindustantimes.com/delhi-news/10-arrested-in-connection-with-jamia-violence-no-students-among-them-say-delhi-police/story-c6O2OdCfRHRpggH4IoTzwK.html"
                                  className="link_sty"
                                >
                                  <u> Hindustan Times</u>
                                </a>
                              </p>
                              <p>
                                [5]
                                <a
                                  href="https://www.indiatoday.in/mail-today/story/citizenship-protests-spread-to-bengal-protesters-torch-5-trains-3-railway-stations-damage-property-1628370-2019-12-15"
                                  className="link_sty"
                                >
                                  <u> India Today</u>
                                </a>
                              </p>
                              <p>
                                [6]
                                <a
                                  href="https://www.news18.com/news/politics/reports-of-bjp-supporters-changing-clothes-to-attack-train-in-murshidabad-true-says-mamata-banerjee-2431151.html"
                                  className="link_sty"
                                >
                                  <u> News 18</u>
                                </a>
                              </p>
                              <p>
                                [7]
                                <a
                                  href="https://www.ndtv.com/india-news/shaheen-bagh-shooter-kapil-baisala-who-shouted-only-hindus-will-rule-gets-bail-2191634"
                                  className="link_sty"
                                >
                                  <u> NDTV</u>
                                </a>
                              </p>
                              <p>
                                [8]
                                <a
                                  href="https://economictimes.indiatimes.com/news/politics-and-nation/shooter-at-shaheen-bagh-aap-member-claims-delhi-police/articleshow/73949070.cms?from=mdr"
                                  className="link_sty"
                                >
                                  <u> The Economic TImes</u>
                                </a>
                              </p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="line_sty"
              style={{ position: "relative", top: "24px" }}
            ></div>
            <Nav className="manageTab4basic p-3 mob_nav_sty">
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`} eventKey="first">
                  <div>
                    <div
                      // onClick={() => setNotic(!notic)}
                      className="line_dots_name"
                    >
                      12 Dec
                    </div>
                    <div
                      // onClick={() => setNotic(!notic)}
                      className="line_dots "
                    ></div>
                  </div>
                </Nav.Link>
                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div>
                    <div
                      // onClick={() => setNoticfirst(!noticfirst)}
                      className="line_dots_name active"
                    >
                      13 Dec
                    </div>
                    <div
                      // onClick={() => setNoticfirst(!noticfirst)}
                      className="line_dots"
                    ></div>
                  </div>
                </Nav.Link>
                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`} eventKey="third">
                  <div
                    // onClick={() => setNoticsecond(!noticsecond)}
                    className="line_dots_name "
                  >
                    15 Dec
                  </div>
                  <div
                    // onClick={() => setNoticsecond(!noticsecond)}
                    className="line_dots"
                  ></div>
                </Nav.Link>
                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div
                    // onClick={() => setNotictheard(!notictheard)}
                    className="line_dots_name"
                  >
                    24 Dec
                  </div>
                  <div
                    // onClick={() => setNotictheard(!notictheard)}
                    className="line_dots"
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div
                    // onClick={() => setNoticfourth(!noticfourth)}
                    className="line_dots_name"
                  >
                    5 Jan
                  </div>
                  <div
                    // onClick={() => setNoticfourth(!noticfourth)}
                    className="line_dots "
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div
                    // onClick={() => setNoticfive(!noticfive)}
                    className="line_dots_name"
                  >
                    10 Jan
                  </div>
                  <div
                    // onClick={() => setNoticfive(!noticfive)}
                    className="line_dots "
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`} eventKey="seven">
                  <div
                    // onClick={() => setNoticsix(!noticsix)}
                    className="line_dots_name"
                  >
                    1 Feb
                  </div>
                  <div
                    // onClick={() => setNoticsix(!noticsix)}
                    className="line_dots"
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {/* {notic ? ( */}
                <div className="notice_dot">
                  <p className="notic_par_sty">
                  The protests across Assam and much of Northeast India that had started when the CAA bill was tabled in the parliament on 4 December intensified as the bill was passed and assumed the status of an act with the assent of the president. As protestors gathered in Guwahati, 2 people were killed in police firing against the protestors.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                {/* {noticfirst ? ( */}
                <div className="notice_dot">
                  <p className="notic_par_sty">
                    The victim died succumbing to her injuries in Sajdarjung
                    Hospital, New Delhi. The autopsy registered the cause of
                    death as “injury to the cervical spine by blunt force
                    trauma”. The victim was cremated at 2:30am by the UP Police.
                    The Allahabad High Court took suo moto cognizance of the
                    case asking the victim’s family, the DM and the SP to appear
                    before it.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="third">
                {/* {noticsecond ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "150px" }}
                >
                  <p className="notic_par_sty">
                  After 3 Delhi Transport buses were torched in Jamia Nagar, police forcefully entered the campus of Jamia Millia Islamia and detained students using batons and tear gas in the process. After solidarity protests at Aligarh Muslim University, the police forcefully entered their campus and attacked students. There were allegations that the police also attacked students not part of the protests.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="fourt">
                {/* {notictheard ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "260px" }}
                >
                  <p className="notic_par_sty">
                    The victim died succumbing to her injuries in Sajdarjung
                    Hospital, New Delhi. The autopsy registered the cause of
                    death as “injury to the cervical spine by blunt force
                    trauma”. The victim was cremated at 2:30am by the UP Police.
                    The Allahabad High Court took suo moto cognizance of the
                    case asking the victim’s family, the DM and the SP to appear
                    before it.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="five">
                {/* {noticfourth ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "370px" }}
                >
                  <p className="notic_par_sty">
                    The victim died succumbing to her injuries in Sajdarjung
                    Hospital, New Delhi. The autopsy registered the cause of
                    death as “injury to the cervical spine by blunt force
                    trauma”. The victim was cremated at 2:30am by the UP Police.
                    The Allahabad High Court took suo moto cognizance of the
                    case asking the victim’s family, the DM and the SP to appear
                    before it.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="six">
                {/* {noticfive ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "500px" }}
                >
                  <p className="notic_par_sty">
                    The victim died succumbing to her injuries in Sajdarjung
                    Hospital, New Delhi. The autopsy registered the cause of
                    death as “injury to the cervical spine by blunt force
                    trauma”. The victim was cremated at 2:30am by the UP Police.
                    The Allahabad High Court took suo moto cognizance of the
                    case asking the victim’s family, the DM and the SP to appear
                    before it.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="seven">
                {/* {noticsix ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "630px" }}
                >
                  <p className="notic_par_sty">
                  After the incident at Jamia Millia Islamia on 30 January where a man opened fire at the protests injuring one student, another man fired bullets in the air during the Shaheen Bagh protests. Both situations had the accused allegedly shouting Hindu fundamentalist slogans while perpetrating the gun violence.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          {/* ===========================end==================================== */}
        </div>
      </Container>
    </div>
  );
}

export default Newscontentsecond;

// ===========================================================================

// import {
//   faFacebookSquare,
//   faInstagramSquare,
//   faTwitter,
// } from "@fortawesome/free-brands-svg-icons";
// import { faBookmark, faShareAlt } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import React, { useState } from "react";
// import { Card, Container } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import Header from "../Header";

// function Newscontentsecond() {
//   const [show, setShow] = useState(false);
//   const [notic, setNotic] = useState(true);
//   return (
//     <div>
//       <Header />
//       <Container>
//         <div class="mt-5">
//           <h1 className="para_hedding">CAA-NRC Protests</h1>
//           <p className="date_line">19 Oct 2020, Monday</p>
//           <div className="row pb-5">
//             <div className="col-md-9">
//               <Card className="card_sty">
//                 <Card.Body>
//                   <div className="row">
//                     <div>
//                       <div className="manage_shear_icon">
//                         <h1 className="para_hedding_newscontent">
//                           Factual discrepancies
//                         </h1>
//                         <div className="manage_shear_icon">
//                           <div
//                             className="margintop_icon"
//                             onClick={() => setShow(!show)}
//                           >
//                             <FontAwesomeIcon
//                               icon={faShareAlt}
//                               style={{ color: "#828282" }}
//                             />
//                             <div>
//                               {show ? (
//                                 <div className="social_icons">
//                                   <FontAwesomeIcon
//                                     className="margintop_10px"
//                                     icon={faTwitter}
//                                     style={{ color: "#828282" }}
//                                   />
//                                   <FontAwesomeIcon
//                                     className="margintop_10px"
//                                     icon={faFacebookSquare}
//                                     style={{ color: "#828282" }}
//                                   />
//                                   <FontAwesomeIcon
//                                     className="margintop_10px"
//                                     icon={faInstagramSquare}
//                                     style={{ color: "#828282" }}
//                                   />
//                                   <FontAwesomeIcon
//                                     className="margintop_10px"
//                                     icon={faBookmark}
//                                     style={{ color: "#828282" }}
//                                   />
//                                 </div>
//                               ) : null}
//                             </div>
//                           </div>
//                         </div>
//                       </div>

//                       <div className="main_para">
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               CAA Protests in Assam are different from the rest
//                               of the country and don’t have religious
//                               discrimination as the primary motivation as
//                               reported by some sources.
//                             </p>
//                           </div>
//                         </div>
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_red_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               It was reported by some media sources that the
//                               student protestors had caused violence and burned
//                               buses resulting in the police entering into Jamia
//                               campus to detain perpetrators. [2]
//                             </p>
//                           </div>
//                         </div>
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_red_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               A few media sources reported that the students had
//                               claimed not being involved in the violence which
//                               was being used to malign the protests and incite
//                               violence. [3]
//                             </p>
//                           </div>
//                         </div>
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_red_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               It was later revealed in the investigations that
//                               the 10 people arrested were not Jamia students and
//                               Jamia student protestors had not been involved in
//                               the violence earlier. [4]
//                             </p>
//                           </div>
//                         </div>
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_green_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               Some reports of violence in West Bengal with
//                               trains being attacked by protestors [5]
//                             </p>
//                           </div>
//                         </div>
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_gary_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               whereas other reports on the issue claimed to
//                               reveal that the people involved in the attack were
//                               affiliated with the BJP. [6]
//                             </p>
//                           </div>
//                         </div>
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_darkred_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               There were claims by some sources that the
//                               shooting at Shaheen Bagh was motivated by the
//                               Union Minister and fueled by BJP supporters. [7]
//                             </p>
//                           </div>
//                         </div>
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_darkred_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               Other sources have alleged that the shooter was
//                               affiliated with the Aam Aadmi Party [8].
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                       {/* <Link to="newscontentfrist" className="pt-3">
//                           <button className="theme_btn">Read Full Story</button>
//                         </Link> */}
//                     </div>
//                   </div>
//                 </Card.Body>
//               </Card>
//             </div>
//             <div className="col-md-3">
//               <Card className="card_sty">
//                 <Card.Body>
//                   <div className="">
//                     <div>
//                       <p>
//                         [1]
//                         <Link to="" className="link_sty">
//                           <u> The Economic Times</u>
//                         </Link>
//                       </p>
//                       <p>
//                         [2]
//                         <Link to="" className="link_sty">
//                           <u> REPUBLIC World</u>
//                         </Link>
//                       </p>
//                       <p>
//                         [3]
//                         <Link to="" className="link_sty">
//                           <u> Scroll</u>
//                         </Link>
//                       </p>
//                       <p>
//                         [4]
//                         <Link to="" className="link_sty">
//                           <u> Hindustan Times</u>
//                         </Link>
//                       </p>
//                       <p>
//                         [5]
//                         <Link to="" className="link_sty">
//                           <u> India Today</u>
//                         </Link>
//                       </p>
//                       <p>
//                         [6]
//                         <Link to="" className="link_sty">
//                           <u> News 18</u>
//                         </Link>
//                       </p>
//                       <p>
//                         [7]
//                         <Link to="" className="link_sty">
//                           <u> NDTV</u>
//                         </Link>
//                       </p>
//                       <p>
//                         [8]
//                         <Link to="" className="link_sty">
//                           <u> The Economic TImes</u>
//                         </Link>
//                       </p>
//                     </div>
//                   </div>
//                 </Card.Body>
//               </Card>
//             </div>
//           </div>
//           <div className="py-5">
//             <div className="line_sty"></div>

//             <div className="d-flex">
//               {" "}
//               <div>
//                 <div className="line_dots_name">12 Dec</div>
//                 <Link to="/datetwelve">
//                   <div className="line_dots"></div>
//                 </Link>
//               </div>
//               <div>
//                 <div className="line_dots_name">13 Dec</div>
//                 <div className="line_dots"></div>
//               </div>
//               <div>
//                 <div className="line_dots_name">15 Dec</div>
//                 <Link to="/datefifteen">
//                   <div className="line_dots"></div>
//                 </Link>
//               </div>
//               <div>
//                 <div className="line_dots_name">24 Dec</div>
//                 <div className="line_dots"></div>
//               </div>
//               <div>
//                 <div className="line_dots_name">5 Jan</div>
//                 <div className="line_dots"></div>
//               </div>
//               <div>
//                 <div className="line_dots_name">10 Jan</div>
//                 <div className="line_dots"></div>
//               </div>
//               <div>
//                 <div
//                   onClick={() => setNotic(!notic)}
//                   className="line_dots_name_empty"
//                 >
//                   1 Feb
//                 </div>
//                 <div
//                   onClick={() => setNotic(!notic)}
//                   className="line_dots_empty"
//                 ></div>
//                 {notic ? (
//                   <div className="notice_dot">
//                     <p className="notic_par_sty">
//                       After the incident at Jamia Millia Islamia on 30 January
//                       where a man opened fire at the protests injuring one
//                       student, another man fired bullets in the air during the
//                       Shaheen Bagh protests. Both situations had the accused
//                       allegedly shouting Hindu fundamentalist slogans while
//                       perpetrating the gun violence.
//                     </p>
//                   </div>
//                 ) : null}
//               </div>
//             </div>
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// }

// export default Newscontentsecond;

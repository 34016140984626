import React from "react";
import Header from "./Header";
import Homecontant from "./Home/Homecontant";

function Home() {
  return (
    <div>
      <Header />
      <Homecontant />
    </div>
  );
}

export default Home;

import "./App.css";
import "./App.max-width.600.css";
import "./App.max-width-992.css";
import { Route, Switch } from "react-router-dom";
import Home from "./Components/Home";
import Newscontentfrist from "./Components/Newscontent/Newscontentfrist";
import Newscontentsecond from "./Components/Newscontent/Newscontentsecond";
import Newscontenttheatd from "./Components/Newscontent/Newscontenttheard";
// import Datetwentytwo from "./Components/Newscontent/DateTwentytwo";
// import Datefourteen from "./Components/Newscontent/Datefourteen";
// import Datetwelve from "./Components/Newscontent/Datetwelvedes";
// import Datefifteen from "./Components/Newscontent/Datefifteen";
// import Datefourteenjan from "./Components/Newscontent/Datefourteenjan";
// import Dateninteen from "./Components/Newscontent/Dateninteen";
// import Test from "./Components/Newscontent/Test";
function App() {
  return (
    <>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/newscontentfrist" component={Newscontentfrist} />
        <Route exact path="/newscontentsecond" component={Newscontentsecond} />
        <Route exact path="/newscontenttheatd" component={Newscontenttheatd} />
        {/* <Route exact path="/datetwentytwo" component={Datetwentytwo} /> */}
        {/* <Route exact path="/datefourteen" component={Datefourteen} /> */}
        {/* <Route exact path="/datetwelve" component={Datetwelve} /> */}
        {/* <Route exact path="/datefifteen" component={Datefifteen} /> */}
        {/* <Route exact path="/datefourteenjan" component={Datefourteenjan} /> */}
        {/* <Route exact path="/dateninteen" component={Dateninteen} />  */}
        {/* <Route exact path="/test" component={Test} />                            */}
      </Switch>
    </>
  );
}

export default App;

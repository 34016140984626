import React, { useState } from "react";
import { Card, Container } from "react-bootstrap";
import police from "../../assets/img/police.png";
import women from "../../assets/img/women.png";
import susant from "../../assets/img/susant.png";
import { Link } from "react-router-dom";

function Homecontant() {
  const [show, setShow] = useState(false);
  const [showone, setShowone] = useState(false);
  const [showsecond, setShowsecond] = useState(false);

  function myFunction() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }
  return (
    <>
      <Container className="pt-5">
        <Card className="card_sty">
          <Card.Body>
            <div className="row">
              <div className="col-sm-9">
                <h1 className="para_hedding">
                  2020 Hathras Gang Rape and Murder Case
                </h1>
                <p className="date_line">19 Oct 2020, Monday</p>
                <div className="main_para">
                  <p>
                    On 14 September 2020, a 19-year-old Dalit woman was
                    gang-raped in Hathras district, Uttar Pradesh, India. After
                    fighting for her life for two weeks, she died in a Delhi
                    hospital. Initially, it was reported that one accused had
                    tried to kill her, though later in her statement to the
                    magistrate, the victim named four accused as having raped
                    her.
                  </p>
                  {show ? (
                    <div>
                      <p>
                        {" "}
                        The police registered a complaint on 20 September 2020
                        and were able to record a statement from the victim on
                        22 September 2020. The victim was initially admitted to
                        the Jawaharlal Nehru Medical College and Hospital in
                        Aligarh on 14 September with her spinal cord severely
                        damaged and was later shifted to Safdarjung Hospital in
                        New Delhi after her conditions worsened.
                      </p>{" "}
                      <p>
                        {" "}
                        She died on 29 September 2020. The victim was cremated
                        at about 2:30am on 29 September 2020 by the Uttar
                        Pradesh Police. Following the cremation, Allahabad High
                        Court has taken suo moto cognizance of the case asking
                        the family, the District Magistrate and the
                        Superintendent of Police to appear before it.
                      </p>
                    </div>
                  ) : null}
                  <div
                    onClick={() => setShow(!show)}
                    className="py-2 showmore_sty"
                  >
                    {show ? "Show Less" : "Read More..."}
                  </div>
                </div>
                <div className="btn_mob">
                  <Link to="/newscontentfrist" className="pt-3">
                    <button className="theme_btn">Read Full Story</button>
                  </Link>
                </div>
              </div>
              <div className="col-sm-3">
                <img src={police} className="img-fluid" alt="police" />
              </div>
            </div>
          </Card.Body>
        </Card>
        <div className="pt-5">
          <Card className="card_sty">
            <Card.Body>
              <div className="row">
                <div className="col-sm-9">
                  <h1 className="para_hedding">CAA-NRC Protests</h1>
                  <p className="date_line">19 Oct 2020, Monday</p>
                  <div className="main_para">
                    <p>
                      The Citizenship Amendment Act protests (CAA Protests) are
                      a series of nationwide protests that occurred after the
                      Government of India introduced and subsequently enacted
                      the Citizenship Amendment Act in the parliament. The
                      protests started primarily in Assam on 4 December 2019
                      after the bill was introduced in the Parliament. Later,
                      protests erupted across Northeast India and eventually
                      spread to the major cities in India.
                    </p>
                    {showone ? (
                      <div>
                        <p>
                          On 15 December, protests were held across the campuses
                          of Jamia Millia Islamia University and Aligarh Muslim
                          University, facing retaliation by the police. Around
                          the same time, protests started in the area of Shaheen
                          Bagh participated in mainly by house wives and old
                          women who were sitting on roads as a sign of peaceful
                          resistance. On 5 January 2020, a masked mob allegedly
                          composed of Hindu extremists armed with rods and
                          sticks attacked the campus of Jawaharlal Nehru
                          University. The act was later alleged to be an attempt
                          to suppress activism in the CAA Protests.
                        </p>
                        Prime Minister Narendra Modi cancelled his visit to
                        Assam while the protests continued in Assam and across
                        the{" "}
                        <p>
                          nation. On 10 January, the Delhi High Court dismissed
                          a plea to remove the protestors occupying Shaheen
                          Bagh, while the CAA Protests had spread to a majority
                          of states in the country.{" "}
                        </p>
                        <p>
                          On 14 January the Kerala Government approached the
                          Supreme Court to challenge the CAA under Section 131
                          of the Constitution and became the first state to do
                          so. By 17 January, inspired by the Shaheen Bagh
                          protests, a massive protest was started in Mumbai and
                          smaller ones in other parts of the country. On 30
                          January, a man opened fire at the protests in Jamia
                          Millia and another on the protestors in Shaheen Bagh
                          on 1 February.
                        </p>
                        <p>
                          Amnesty International subsequently informed the US
                          lawmakers that the CAA clearly violated International
                          human rights and legitimized discrimination on the
                          basis of religion. On 3 March, the UN High
                          Commissioner for human rights filed an intervention
                          plea in the Supreme Court to be made a party in the
                          CAA Protest case.
                        </p>
                      </div>
                    ) : null}
                    <div
                      onClick={() => setShowone(!showone)}
                      className="py-2 showmore_sty"
                    >
                      {showone ? "Show Less" : "Read More..."}
                    </div>
                  </div>
                  <div className="btn_mob">
                    <Link to="/newscontentsecond" className="pt-3">
                      <button className="theme_btn">Read Full Story</button>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-3">
                  <img src={women} className="img-fluid" alt="women" />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>

        <div className="pt-5">
          <Card className="card_sty">
            <Card.Body>
              <div className="row">
                <div className="col-sm-9">
                  <h1 className="para_hedding">
                    Sushant Singh Rajput death case and investigation
                  </h1>
                  <p className="date_line">19 Oct 2020, Monday</p>
                  <div className="main_para">
                    <p>
                      On 14 June 2020, Indian actor Sushant Singh Rajput was
                      found dead hanging from the ceiling fan at his home in
                      Bandra, Mumbai, India. A team of three doctors submitted a
                      provisional post-mortem with the cause of death mentioned
                      as “asphyxia due to hanging”. A final post-mortem report
                      was received on 25 June.{" "}
                    </p>
                    {showsecond ? (
                      <div>
                        <p>
                          On 25 July 2020, Sushant’s father, KK Singh, filed an
                          FIR in Patna against Rhea Chakraborty and six others,
                          including her family members, for abetment of suicide.
                          The Enforcement Directorate (ED), which investigates
                          financial crimes, also registered a money laundering
                          case over transactions worth 150 million based on the
                          complaint registered by Sushant’s father. In a
                          petition filed to the Supreme Court, Rhea Chakraborty
                          claimed that she had no role in the death of Sushant
                          and also requested for the FIR to be transferred to
                          the Mumbai Police. The Mumbai police said there was no
                          misappropriation of funds and appointed Grant Thornton
                          as the financial forensic auditor on 4 August.{" "}
                        </p>
                        <p>
                          On 6 August 2020, the Central Bureau of Investigation
                          (CBI) re-registered the FIR and on 19 August, the
                          Supreme Court allowed the CBI to take control of the
                          investigation. On 26 August, the Narcotics Control
                          Bureau (NCB) registered an FIR against Rhea
                          Chakraborty, her brother Showik, and three others. On
                          3 October 2020, Dr Sudhir Gupta of AIIMS, leader of
                          the forensic team designated by CBI reported that
                          Sushant’s death is a case of suicide. On 5 October,
                          ANI reported that AIIMS had submitted their medical
                          report directly to the CBI for further review.
                        </p>
                      </div>
                    ) : null}
                    <div
                      onClick={() => setShowsecond(!showsecond)}
                      className="py-2 showmore_sty"
                    >
                      {showsecond ? "Show Less" : "Read More..."}
                    </div>
                  </div>
                  <div className="btn_mob">
                    <Link to="/newscontenttheatd" className="pt-3">
                      <button className="theme_btn">Read Full Story</button>
                    </Link>
                  </div>
                </div>
                <div className="col-sm-3">
                  <img src={susant} className="img-fluid" alt="susant" />
                </div>
              </div>
            </Card.Body>
          </Card>
        </div>
      </Container>
    </>
  );
}

export default Homecontant;

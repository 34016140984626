import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faBookmark, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState } from "react";
import { Card, Container, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../Header";

function Newscontenttheatd() {
  const [show, setShow] = useState(false);
  const [dotshow, setDotshow] = useState(false);

  return (
    <div>
      <Header />
      <Container>
        <div>
          {/* =========================================tast tab==================================== */}
          <Tab.Container
            id="left-tabs-example"
            className="RequireMentSectionTab"
            defaultActiveKey="six"
          >
            {" "}
            <div>
              <div eventKey="">
                <div class="mt-5">
                  <h1 className="para_hedding">
                    Sushant Singh Rajput death case and investigation
                  </h1>
                  <p className="date_line">19 Oct 2020, Monday</p>
                  <div className="row pb-5">
                    <div className="col-md-9">
                      <Card className="card_sty">
                        <Card.Body>
                          <div className="row">
                            <div>
                              <div className="manage_shear_icon">
                                <h1 className="para_hedding_newscontent">
                                  Factual discrepancies
                                </h1>
                                <div
                                  className="margintop_icon"
                                  onClick={() => setShow(!show)}
                                >
                                  <FontAwesomeIcon
                                    icon={faShareAlt}
                                    style={{ color: "#828282" }}
                                  />
                                  <div>
                                    {show ? (
                                      <div className="social_icons">
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faTwitter}
                                          style={{ color: "#828282" }}
                                        />
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faFacebookSquare}
                                          style={{ color: "#828282" }}
                                        />
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faInstagramSquare}
                                          style={{ color: "#828282" }}
                                        />
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faBookmark}
                                          style={{ color: "#828282" }}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="main_para">
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding "></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      There have been reports on the conclusion
                                      of the case by a few sources [1]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      but the CBI has clarifed on October 15th
                                      that all such reports are erroneous and
                                      the investigation still continues. [2]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      There have been reports by a few media
                                      houses regarding allegations and alleged
                                      proofs against Rhea Chakraborty dealing
                                      drugs whereas the Mumbai High Court later
                                      released a statement saying that she is
                                      not part of a chain of drug dealers [3]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      Some media sources raised quiestions on
                                      the “conclusive” nature of the report
                                      submitted by AIIMS to the CBI [4]
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-3">
                      <Card className="card_sty">
                        <Card.Body>
                          <div className="right_card_sty_therd">
                            <div>
                              <p>
                                [1]
                                <a
                                  href="https://thefederal.com/news/after-aiims-cbi-finds-no-traces-of-homicide-in-sushants-death/"
                                  className="link_sty"
                                >
                                  <u> The Federal</u>
                                </a>
                              </p>
                              <p>
                                [2]
                                <a
                                  href="https://www.freepressjournal.in/entertainment/bollywood/reports-on-sushant-singh-rajput-case-speculative-and-erroneous-cbi"
                                  className="link_sty"
                                >
                                  <u> The FREE PRESS Journal</u>
                                </a>
                              </p>
                              <p>
                                [3]
                                <a
                                  href="https://mumbaimirror.indiatimes.com/entertainment/bollywood/rhea-chakraborty-bail-order-highlights-bombay-high-court-says-she-is-not-part-of-chain-of-drug-dealers/articleshow/78531863.cms"
                                  className="link_sty"
                                >
                                  <u> Mumbai Mirror</u>
                                </a>
                              </p>
                              <p>
                                [4]
                                <a
                                  href="https://thewire.in/government/aiims-forensic-report-conclusive-findings-sushant-singh-rajput-case-cbi"
                                  className="link_sty"
                                >
                                  <u> The WIRE</u>
                                </a>
                              </p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="line_sty"
              style={{ position: "relative", top: "24px" }}
            ></div>
            <Nav className="manageTab4basic  p-3 mob_nav_sty">
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`} eventKey="first">
                  <div>
                    <div
                      // onClick={() => setNotic(!notic)}
                      className="line_dots_name"
                    >
                      14 June
                    </div>
                    <div
                      // onClick={() => setNotic(!notic)}
                      className="line_dots "
                    ></div>
                  </div>
                </Nav.Link>
                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div>
                    <div
                      // onClick={() => setNoticfirst(!noticfirst)}
                      className="line_dots_name active"
                    >
                      25 July
                    </div>
                    <div
                      // onClick={() => setNoticfirst(!noticfirst)}
                      className="line_dots"
                    ></div>
                  </div>
                </Nav.Link>
                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`} eventKey="third">
                  <div
                    // onClick={() => setNoticsecond(!noticsecond)}
                    className="line_dots_name "
                  >
                    19 Aug
                  </div>
                  <div
                    // onClick={() => setNoticsecond(!noticsecond)}
                    className="line_dots"
                  ></div>
                </Nav.Link>
                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div
                    // onClick={() => setNotictheard(!notictheard)}
                    className="line_dots_name"
                  >
                    26 Aug
                  </div>
                  <div
                    // onClick={() => setNotictheard(!notictheard)}
                    className="line_dots"
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div
                    // onClick={() => setNoticfourth(!noticfourth)}
                    className="line_dots_name"
                  >
                    9 Sep
                  </div>
                  <div
                    // onClick={() => setNoticfourth(!noticfourth)}
                    className="line_dots "
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`} eventKey="six">
                  <div
                    // onClick={() => setNoticfive(!noticfive)}
                    className="line_dots_name"
                  >
                    3 Oct
                  </div>
                  <div
                    // onClick={() => setNoticfive(!noticfive)}
                    className="line_dots "
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {/* {notic ? ( */}
                <div className="notice_dot">
                  <p className="notic_par_sty">
                  The actor was found dead after friends broke into his room to check. The Mumbai Police commenced an investigation, stating that the death was being treated as suicide. Soon after news of his death, there were several debates sparked around mental health on social media. No suicide note was found from the spot where his body was recovered.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                {/* {noticfirst ? ( */}
                <div className="notice_dot">
                  <p className="notic_par_sty">
                    It was reported by the media that Dr Sudhir Gupta of AIIMS,
                    leader of the forensic team by CBI declared that Sushant’s
                    death was a suicide and murder had been completely ruled
                    out. Later the CBI clarified that the investigation in
                    Sushant’s death is still continuing and all reports to the
                    contrary are merely speculative at the moment.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="third">
                {/* {noticsecond ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "150px" }}
                >
                  <p className="notic_par_sty">
                  After initial investigation in the FIR by Sushant's father by the Mumbai Police and the CBI re-registering the Patna FIR thus taking over the case, the Supreme Court ordered the CBI to look into any future cases related to Sushant's death. There had been clashes between the investigations of Bihar and Mumbai police during this time.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="fourt">
                {/* {notictheard ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "260px" }}
                >
                  <p className="notic_par_sty">
                    It was reported by the media that Dr Sudhir Gupta of AIIMS,
                    leader of the forensic team by CBI declared that Sushant’s
                    death was a suicide and murder had been completely ruled
                    out. Later the CBI clarified that the investigation in
                    Sushant’s death is still continuing and all reports to the
                    contrary are merely speculative at the moment.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="five">
                {/* {noticfourth ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "370px" }}
                >
                  <p className="notic_par_sty">
                    It was reported by the media that Dr Sudhir Gupta of AIIMS,
                    leader of the forensic team by CBI declared that Sushant’s
                    death was a suicide and murder had been completely ruled
                    out. Later the CBI clarified that the investigation in
                    Sushant’s death is still continuing and all reports to the
                    contrary are merely speculative at the moment.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="six">
                {/* {noticfive ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "500px" }}
                >
                  <p className="notic_par_sty">
                    It was reported by the media that Dr Sudhir Gupta of AIIMS,
                    leader of the forensic team by CBI declared that Sushant’s
                    death was a suicide and murder had been completely ruled
                    out. Later the CBI clarified that the investigation in
                    Sushant’s death is still continuing and all reports to the
                    contrary are merely speculative at the moment.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="seven">
                {/* {noticsix ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "630px" }}
                >
                  <p className="notic_par_sty">
                    It was reported by the media that Dr Sudhir Gupta of AIIMS,
                    leader of the forensic team by CBI declared that Sushant’s
                    death was a suicide and murder had been completely ruled
                    out. Later the CBI clarified that the investigation in
                    Sushant’s death is still continuing and all reports to the
                    contrary are merely speculative at the moment.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          {/* ===========================end==================================== */}
        </div>
      </Container>
    </div>
  );
}

export default Newscontenttheatd;

// ================================================================================================

// import {
//   faFacebookSquare,
//   faInstagramSquare,
//   faTwitter,
// } from "@fortawesome/free-brands-svg-icons";
// import { faBookmark, faShareAlt } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import React, { useState } from "react";
// import { Card, Container } from "react-bootstrap";
// import { Link } from "react-router-dom";
// import Header from "../Header";

// function Newscontenttheatd() {
//   const [show, setShow] = useState(false);
//   const [notic, setNotic] = useState(true);

//   return (
//     <div>
//       <Header />
//       <Container>
//         <div class="mt-5">
//           <h1 className="para_hedding">
//             Sushant Singh Rajput death case and investigation
//           </h1>
//           <p className="date_line">19 Oct 2020, Monday</p>
//           <div className="row pb-5">
//             <div className="col-md-9">
//               <Card className="card_sty">
//                 <Card.Body>
//                   <div className="row">
//                     <div>
//                       <div className="manage_shear_icon">
//                         <h1 className="para_hedding_newscontent">
//                           Factual discrepancies
//                         </h1>
//                         <div
//                           className="margintop_icon"
//                           onClick={() => setShow(!show)}
//                         >
//                           <FontAwesomeIcon
//                             icon={faShareAlt}
//                             style={{ color: "#828282" }}
//                           />
//                           <div>
//                             {show ? (
//                               <div className="social_icons">
//                                 <FontAwesomeIcon
//                                   className="margintop_10px"
//                                   icon={faTwitter}
//                                   style={{ color: "#828282" }}
//                                 />
//                                 <FontAwesomeIcon
//                                   className="margintop_10px"
//                                   icon={faFacebookSquare}
//                                   style={{ color: "#828282" }}
//                                 />
//                                 <FontAwesomeIcon
//                                   className="margintop_10px"
//                                   icon={faInstagramSquare}
//                                   style={{ color: "#828282" }}
//                                 />
//                                 <FontAwesomeIcon
//                                   className="margintop_10px"
//                                   icon={faBookmark}
//                                   style={{ color: "#828282" }}
//                                 />
//                               </div>
//                             ) : null}
//                           </div>
//                         </div>
//                       </div>

//                       <div className="main_para">
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_red_headding "></div>
//                           </div>

//                           <div>
//                             <p>
//                               There have been reports on the conclusion of the
//                               case by a few sources [1]
//                             </p>
//                           </div>
//                         </div>
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               but the CBI has clarifed on October 15th that all
//                               such reports are erroneous and the investigation
//                               still continues. [2]
//                             </p>
//                           </div>
//                         </div>
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_red_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               There have been reports by a few media houses
//                               regarding allegations and alleged proofs against
//                               Rhea Chakraborty dealing drugs whereas the Mumbai
//                               High Court later released a statement saying that
//                               she is not part of a chain of drug dealers [3]
//                             </p>
//                           </div>
//                         </div>
//                         <div className="d-flex">
//                           <div>
//                             <div className="dots_green_headding"></div>
//                           </div>

//                           <div>
//                             <p>
//                               Some media sources raised quiestions on the
//                               “conclusive” nature of the report submitted by
//                               AIIMS to the CBI [4]
//                             </p>
//                           </div>
//                         </div>
//                       </div>
//                       {/* <Link to="newscontentfrist" className="pt-3">
//                             <button className="theme_btn">Read Full Story</button>
//                           </Link> */}
//                     </div>
//                   </div>
//                 </Card.Body>
//               </Card>
//             </div>
//             <div className="col-md-3">
//               <Card className="card_sty">
//                 <Card.Body>
//                   <div className="">
//                     <div>
//                       <p>
//                         [1]
//                         <Link to="" className="link_sty">
//                           <u> The Federal</u>
//                         </Link>
//                       </p>
//                       <p>
//                         [2]
//                         <Link to="" className="link_sty">
//                           <u> The FREE PRESS Journal</u>
//                         </Link>
//                       </p>
//                       <p>
//                         [3]
//                         <Link to="" className="link_sty">
//                           <u> Mumbai Mirror</u>
//                         </Link>
//                       </p>
//                       <p>
//                         [4]
//                         <Link to="" className="link_sty">
//                           <u> The WIRE</u>
//                         </Link>
//                       </p>
//                     </div>
//                   </div>
//                 </Card.Body>
//               </Card>
//             </div>
//           </div>
//           <div className="py-5">
//             <div className="line_sty"></div>

//             <div className="d-flex">
//               {" "}
//               <div>
//                 <div className="line_dots_name">14 Jun</div>
//                 <Link to="/datefourteenjan">
//                   <div className="line_dots"></div>
//                 </Link>
//               </div>
//               <div>
//                 <div className="line_dots_name">25 July</div>
//                 <div className="line_dots"></div>
//               </div>
//               <div>
//                 <div className="line_dots_name">19 Aug</div>
//                 <Link to="/dateninteen">
//                   <div className="line_dots"></div>
//                 </Link>
//               </div>
//               <div>
//                 <div className="line_dots_name">26 Aug</div>
//                 <div className="line_dots"></div>
//               </div>
//               <div>
//                 <div className="line_dots_name">9 Sep</div>
//                 <div className="line_dots"></div>
//               </div>
//               <div>
//                 <div
//                   onClick={() => setNotic(!notic)}
//                   className="line_dots_name_empty"
//                 >
//                   3 Oct
//                 </div>
//                 <div
//                   onClick={() => setNotic(!notic)}
//                   className="line_dots_empty"
//                 ></div>
//                 {notic ? (
//                   <div className="notice_dot">
//                     <p className="notic_par_sty">
//                       It was reported by the media that Dr Sudhir Gupta of
//                       AIIMS, leader of the forensic team by CBI declared that
//                       Sushant’s death was a suicide and murder had been
//                       completely ruled out. Later the CBI clarified that the
//                       investigation in Sushant’s death is still continuing and
//                       all reports to the contrary are merely speculative at the
//                       moment.
//                     </p>
//                   </div>
//                 ) : null}
//               </div>
//             </div>
//           </div>
//         </div>
//       </Container>
//     </div>
//   );
// }

// export default Newscontenttheatd;

import {
  faFacebookSquare,
  faInstagramSquare,
  faTwitter,
} from "@fortawesome/free-brands-svg-icons";
import { faBookmark, faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import React, { useState } from "react";
import { Card, Container, Nav, Tab } from "react-bootstrap";
import { Link } from "react-router-dom";
import Header from "../Header";

function Newscontentfrist() {
  const [show, setShow] = useState(false);
  const [dotshow, setDotshow] = useState(false);

  return (
    <div>
      <Header />
      <Container>
        <div>
          {/* =========================================tast tab==================================== */}
          <Tab.Container
            id="left-tabs-example"
            className="RequireMentSectionTab"
            defaultActiveKey="seven"
          >
            {" "}
            <div>
              <div eventKey="">
                <div class="mt-5">
                  <h1 className="para_hedding">
                    2020 Hathras Gang Rape and Murder Case
                  </h1>
                  <p className="date_line">19 Oct 2020, Monday</p>
                  <div className="row pb-5">
                    <div className="col-md-9">
                      <Card className="card_sty">
                        <Card.Body>
                          <div className="row">
                            <div>
                              <div className="manage_shear_icon">
                                <h1 className="para_hedding_newscontent">
                                  Factual discrepancies
                                </h1>
                                <div
                                  className="margintop_icon"
                                  onClick={() => setShow(!show)}
                                >
                                  <FontAwesomeIcon
                                    icon={faShareAlt}
                                    style={{ color: "#828282" }}
                                  />
                                  <div>
                                    {show ? (
                                      <div className="social_icons">
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faTwitter}
                                          style={{ color: "#828282" }}
                                        />
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faFacebookSquare}
                                          style={{ color: "#828282" }}
                                        />
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faInstagramSquare}
                                          style={{ color: "#828282" }}
                                        />
                                        <FontAwesomeIcon
                                          className="margintop_10px"
                                          icon={faBookmark}
                                          style={{ color: "#828282" }}
                                        />
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>

                              <div className="main_para">
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      There are some news sources which reported
                                      that the victim was cremated in the middle
                                      of the night without the permission of the
                                      family and the authorities are misleading
                                      people saying the family had been present
                                      at the funeral. [1]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      There are some news sources which reported
                                      that the police had taken permissions from
                                      the family prior to cremation and the
                                      family was now lying. [2]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      Some news sources reported that the UP
                                      administration and police have clearly
                                      established using forensic evidence that
                                      no rape occured. [3]
                                    </p>
                                  </div>
                                </div>
                                <div className="d-flex">
                                  <div>
                                    <div className="dots_headding"></div>
                                  </div>

                                  <div>
                                    <p className="font_norma">
                                      Some news have reported that the
                                      administration is pushing false propoganda
                                      and questioned the analysis of “no rape”
                                      with the victims official statement
                                      mentioning rape. [4]
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                    <div className="col-md-3">
                      <Card className="card_sty">
                        <Card.Body>
                          <div className="right_card_sty_first">
                            <div>
                              <p>
                                [1]
                                <a
                                  href="https://thewire.in/media/misleading-video-hathras-victim-family-funeral"
                                  className="link_sty"
                                >
                                  <u> The WIRE</u>{" "}
                                </a>
                              </p>
                              <p>
                                [2]
                                <a
                                  href="https://www.timesnownews.com/india/article/hathras-gang-rape-case-adg-claims-police-took-consent-from-victims-family-before-funeral/660546"
                                  className="link_sty"
                                >
                                  <u> TIMES NOW</u>
                                </a>
                              </p>
                              <p>
                                [3]
                                <a
                                  href="https://www.hindustantimes.com/india-news/hathras-gang-rape-case-samples-sent-to-agra-forensic-lab-preliminary-report-doesn-t-confirm-rape-claim-police/story-f22lpDy1a6PMvFrHSPcVqL.html"
                                  className="link_sty"
                                >
                                  <u> Hindustan Times</u>
                                </a>
                              </p>
                              <p>
                                [4]
                                <a
                                  href="https://scroll.in/article/974972/hathras-case-this-is-what-is-wrong-with-the-no-sperm-no-rape-line-being-pushed-by-up-police"
                                  className="link_sty"
                                >
                                  <u> Scroll</u>
                                </a>
                              </p>
                            </div>
                          </div>
                        </Card.Body>
                      </Card>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="line_sty"
              style={{ position: "relative", top: "24px" }}
            ></div>
            <Nav className="manageTab4basic p-3 ">
              <Nav.Item className="Custom-Tab-Nav-Item ">
                <Nav.Link className={`Custom-tab-link`} eventKey="first">
                  <div>
                    <div
                      // onClick={() => setNotic(!notic)}
                      className="line_dots_name"
                    >
                      14 Sep
                    </div>
                    <div
                      // onClick={() => setNotic(!notic)}
                      className="line_dots "
                    ></div>
                  </div>
                </Nav.Link>
                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div>
                    <div
                      // onClick={() => setNoticfirst(!noticfirst)}
                      className="line_dots_name active"
                    >
                      20 Sep
                    </div>
                    <div
                      // onClick={() => setNoticfirst(!noticfirst)}
                      className="line_dots"
                    ></div>
                  </div>
                </Nav.Link>
                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`} eventKey="third">
                  <div
                    // onClick={() => setNoticsecond(!noticsecond)}
                    className="line_dots_name "
                  >
                    22 Sep
                  </div>
                  <div
                    // onClick={() => setNoticsecond(!noticsecond)}
                    className="line_dots"
                  ></div>
                </Nav.Link>
                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div
                    // onClick={() => setNotictheard(!notictheard)}
                    className="line_dots_name"
                  >
                    24 Sep
                  </div>
                  <div
                    // onClick={() => setNotictheard(!notictheard)}
                    className="line_dots"
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div
                    // onClick={() => setNoticfourth(!noticfourth)}
                    className="line_dots_name"
                  >
                    26 Sep
                  </div>
                  <div
                    // onClick={() => setNoticfourth(!noticfourth)}
                    className="line_dots "
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`}>
                  <div
                    // onClick={() => setNoticfive(!noticfive)}
                    className="line_dots_name"
                  >
                    28 Sep
                  </div>
                  <div
                    // onClick={() => setNoticfive(!noticfive)}
                    className="line_dots "
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
              <Nav.Item className="Custom-Tab-Nav-Item">
                <Nav.Link className={`Custom-tab-link`} eventKey="seven">
                  <div
                    // onClick={() => setNoticsix(!noticsix)}
                    className="line_dots_name"
                  >
                    29 Sep
                  </div>
                  <div
                    // onClick={() => setNoticsix(!noticsix)}
                    className="line_dots"
                  ></div>
                </Nav.Link>

                <div className="Active-Dot"></div>
              </Nav.Item>
            </Nav>
            <Tab.Content>
              <Tab.Pane eventKey="first">
                {/* {notic ? ( */}
                <div className="notice_dot">
                  <p className="notic_par_sty">
                  The victim, 19-year old Dalit woman went to a farm to collect cattle fodder and was allegedly raped and dragged by her neck injuring her spinal cord in the process. Her cries were heard by her mother who then found her lying in the field. She was admitted to the jawaharlal Nehru Medical College and complaint lodged at the chand Pa police station where the police rejected the family's claims.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="second">
                {/* {noticfirst ? ( */}
                <div className="notice_dot">
                  <p className="notic_par_sty">
                    The victim died succumbing to her injuries in Sajdarjung
                    Hospital, New Delhi. The autopsy registered the cause of
                    death as “injury to the cervical spine by blunt force
                    trauma”. The victim was cremated at 2:30am by the UP Police.
                    The Allahabad High Court took suo moto cognizance of the
                    case asking the victim’s family, the DM and the SP to appear
                    before it.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="third">
                {/* {noticsecond ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "150px" }}
                >
                  <p className="notic_par_sty">
                  The police finally had registered a complaint on 20 sep. Initially registered as assault and SC/ST Act case, gangrape was later added to the list of charges after the police were able to take an official statement from the victim. In her three recorded statements she mentioned that she was raped and strangulated when she tried to resist. She was later shifted to Safdarjung Hospital after her condition worsened.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="fourt">
                {/* {notictheard ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "260px" }}
                >
                  <p className="notic_par_sty">
                    The victim died succumbing to her injuries in Sajdarjung
                    Hospital, New Delhi. The autopsy registered the cause of
                    death as “injury to the cervical spine by blunt force
                    trauma”. The victim was cremated at 2:30am by the UP Police.
                    The Allahabad High Court took suo moto cognizance of the
                    case asking the victim’s family, the DM and the SP to appear
                    before it.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="five">
                {/* {noticfourth ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "370px" }}
                >
                  <p className="notic_par_sty">
                    The victim died succumbing to her injuries in Sajdarjung
                    Hospital, New Delhi. The autopsy registered the cause of
                    death as “injury to the cervical spine by blunt force
                    trauma”. The victim was cremated at 2:30am by the UP Police.
                    The Allahabad High Court took suo moto cognizance of the
                    case asking the victim’s family, the DM and the SP to appear
                    before it.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="six">
                {/* {noticfive ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "500px" }}
                >
                  <p className="notic_par_sty">
                    The victim died succumbing to her injuries in Sajdarjung
                    Hospital, New Delhi. The autopsy registered the cause of
                    death as “injury to the cervical spine by blunt force
                    trauma”. The victim was cremated at 2:30am by the UP Police.
                    The Allahabad High Court took suo moto cognizance of the
                    case asking the victim’s family, the DM and the SP to appear
                    before it.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>

              <Tab.Pane eventKey="seven">
                {/* {noticsix ? ( */}
                <div
                  className="notice_dot mobsty"
                  style={{ marginLeft: "630px" }}
                >
                  <p className="notic_par_sty">
                  The victim died succumbing to her injuries in Safdarjung Hospital, New Delhi. The autopsy registered the cause of death as "injury to the cervical spine by blunt force trauma". The victim was cremated at 2:30am by the UP Police. The Allahabad High Court took suo moto cognizance of the case asking the victim's family, the DM and the SP to appear before it.
                  </p>
                </div>
                {/* ) : null} */}
              </Tab.Pane>
            </Tab.Content>
          </Tab.Container>
          {/* ===========================end==================================== */}
        </div>
      </Container>
    </div>
  );
}

export default Newscontentfrist;
